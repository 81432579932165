import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY, COLORS, COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import { FlyoutBody } from 'components/publicWebsiteV2/common/FlyoutPanel/FlyoutPanel.styles';
import {
  PhotosContainer,
  Photo,
} from 'components/publicWebsiteV2/pages/Gallery/components/GalleryPhotos/GalleryPhotos.styles';

export const Lightbox = styled.div`
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
`;

export const LightboxImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const CloseLightboxBtn = styled.button`
  position: fixed;
  top: ${SPACING.MD};
  right: ${SPACING.MD};
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  outline: none;
  border: none;
  background: none;
  color: ${COLORS.WHITE};
  &:focus {
    outline: highlight auto 2px;
    outline: -webkit-focus-ring-color auto 2px;
  }
`;

export const SlideshowContainer = styled.div`
  display: grid;
  grid-gap: ${SPACING.S24};
  grid-template-columns: 56px auto 56px;
  grid-template-rows: minmax(100px, max-content);
  padding: 60px ${SPACING.MD} 0;

  ${MEDIA_QUERY.DESKTOP_LG} {
    grid-gap: 120px;
    grid-template-rows: minmax(100px, 100%);
    height: calc(100% - 100px);
    padding: 60px;
  }
`;

export const LightboxImageSection = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  position: relative;
`;

export const LightboxImage = styled.img`
  max-height: 100%;
  ${MEDIA_QUERY.DESKTOP_LG} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LightboxCaption = styled.p`
  color: ${COLORS.WHITE};
  margin-top: ${SPACING.S32};
  ${MEDIA_QUERY.DESKTOP_LG} {
    margin-top: -40px;
  }

  &:empty {
    display: none;
  }
`;

export const VideosContainer = styled.div`
  margin-top: ${SPACING.XL};
  text-align: center;
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const NoVisuals = styled.div`
  text-align: center;
`;

export const GalleryPageContainer = styled.div`
  width: 100%;
  max-width: 100%;

  ${FlyoutBody} {
    margin: 0;

    ${PhotosContainer} {
      margin: 0;

      ${Photo} {
        border: 1px solid ${COLORS3.BLACK_015};
      }
    }
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CaretContainerButton = styled.button<{ hide?: boolean }>`
  align-items: center;
  background: ${COLORS3.CLOUD_100};
  border-radius: 100px;
  color: ${COLORS3.BLACK_100};
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: center;
  padding: ${SPACING.S16};
  width: 56px;
  ${({ hide }) => hide && 'visibility: hidden;'}
`;
