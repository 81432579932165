import type { WCmsPhotoView } from '@zola/svc-web-api-ts-client';

/* chunkGalleryArray groups the photos by arrays with lengths of: 4, 5, 4, 4, repeat */
const chunkGalleryArray = (photos: Array<WCmsPhotoView>): Array<Array<WCmsPhotoView>> => {
  const a = photos.slice();
  const ret = [];
  let i = 0;
  while (a.length > 0) {
    if (i % 5 === 1 || i % 5 === 4) {
      ret.push(a.slice(0, 5));
      a.splice(0, 5);
    } else {
      ret.push(a.slice(0, 4));
      a.splice(0, 4);
    }
    i += 1;
  }
  return ret;
};

export default chunkGalleryArray;
