import styled from '@emotion/styled';
import ImageBaseComponent from 'components/publicWebsiteV2/common/Image';

export const ImageSized = styled(ImageBaseComponent)`
  margin: 0 auto ${({ theme }) => theme.SPACING.S10};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;

  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    margin-bottom: ${({ theme }) => theme.SPACING.S32};
  }
`;

export const Title = styled.h1<{ alwaysShow?: boolean }>`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${({ theme }) => theme.SPACING.S10};
  text-align: center;
  width: 100%;
  ${({ alwaysShow, theme }) =>
    !alwaysShow &&
    `${theme.MEDIA_QUERY.DESKTOP} {
    display: none;
  }`}
`;

export const Description = styled.div`
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
  white-space: pre-wrap; // preserve line breaks
  width: 100%;
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  width: 100%;
  &:not(:empty) {
    margin-bottom: ${({ theme }) => theme.SPACING.LG};
  }
`;

const preventOverflowStyles = `
  overflow-wrap: break-word; // prevent long words from overflowing
  width: 100%;
`;

export const DescriptionInnerContainer = styled.span`
  ${preventOverflowStyles}
`;

export const TitleInnerContainer = styled.span`
  ${preventOverflowStyles}
`;
